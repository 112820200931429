// src/components/Admin/AdminPage.js

import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const AdminPage = () => {
  const [products, setProducts] = useState([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(firestore, 'products');
      const productsSnapshot = await getDocs(productsCollection);
      const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
    };
    fetchProducts();
  }, []);

  const handleDeleteProduct = async (id) => {
    try {
      const productDoc = doc(firestore, 'products', id);
      await deleteDoc(productDoc);
      setProducts(products.filter(product => product.id !== id));
    } catch (error) {
      console.error('Error deleting product: ', error);
    }
  };

  if (!currentUser) {
    return <p>Please log in as an admin to view this page.</p>;
  }

  return (
    <div className="admin-product-page">
      <h2>Admin Page</h2>
      <button onClick={() => navigate('/admin/add')}>Add New Product</button>
      
      <div className="admin-product-container">
      <h3>Manage Products</h3>
      <ul>
        {products.map(product => (
          <li key={product.id}>
            Name:{product.name} | Section:{product.section} | Price:EUR{product.price.toFixed(2)} | Quantity:{product.availability[0]?.quantity}
            <button onClick={() => navigate(`/admin/edit/${product.id}`)}>Edit</button>
            <button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
          </li>
        ))}
      </ul>
      </div>
    </div>
  );
};

export default AdminPage;
