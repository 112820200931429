import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import MapComponent from './MapComponent';
import '../styles.css';
import { useForm } from 'react-hook-form';

const ContactPage = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [result, setResult] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  useEffect(() => {
    if (captchaToken) {
      setValue('recaptcha_response', captchaToken);
    }
  }, [captchaToken, setValue]);

  const handleRecaptcha = (event) => {
    event.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LeJIxoqAAAAANBTh27JTWpiooT8W3YRoYtb55RH', { action: 'submit' }).then((token) => {
        setCaptchaToken(token);
        // submit form only after reCAPTCHA token is set
        document.getElementById('contact-form').submit();
      });
    });
  };

  const onSubmit = async (data) => {
    setResult('Sending....');
    const formData = new FormData();

    formData.append('access_key', '0ea88d8a-ac1b-415c-8bda-852faf6fa261');

    for (const key in data) {
      if (key === 'file') {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    }

    const res = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    }).then((res) => res.json());

    if (res.success) {
      setResult(res.message);
      document.getElementById('contact-form').reset();
    } else {
      setResult(res.message);
    }
  };

  const handleFormSubmit = (data) => {
    if (!captchaToken) {
      setResult('Please complete the reCAPTCHA.');
      return;
    }
    onSubmit(data);
  };

  return (
    <div className="contact-page">
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LeJIxoqAAAAANBTh27JTWpiooT8W3YRoYtb55RH" async defer></script>
      </Helmet>
      <h1>Contact Us</h1>
      <div className="contact-container">
        <div className="container-text">
          <div>
            <h2>We are here to help!</h2>
            <p>
              Let us know how we can best serve you. Use the contact form to email us or select from the topics below that best fit your needs. It's an honor to support you in your journey towards better health.
            </p>
          </div>
          <form id="contact-form" onSubmit={handleSubmit(handleFormSubmit)}>
            <input type="text" placeholder="Name" {...register('name')} />
            <input type="email" placeholder="Email" {...register('email')} />
            <textarea name="message" {...register('message')} required></textarea>
            <input
              type="hidden"
              {...register('recaptcha_response')}
              id="recaptchaResponse"
            />
              <div>
              This site is protected by the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </div>
            <button type="submit" onClick={handleRecaptcha} className="g-recaptcha" data-sitekey="6LeJIxoqAAAAANBTh27JTWpiooT8W3YRoYtb55RH" data-callback="onSubmit" data-action="submit">
              SEND MESSAGE
            </button>
          </form>
          <br />
          <span>{result}</span>
        </div>
        <div className="map-section">
          <MapComponent />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
