import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useCart } from '../contexts/CartContext';
import '../styles.css';

const ProductDetailsPage = () => {
  const { productId, section } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [mainImage, setMainImage] = useState('');
  const { addToCart, updateCartItem, cart } = useCart();
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    const fetchProduct = async () => {
      if (!productId) {
        setError('No productId provided');
        setLoading(false);
        return;
      }

      try {
        const productDoc = await getDoc(doc(firestore, 'products', productId));
        if (productDoc.exists()) {
          const productData = { id: productDoc.id, ...productDoc.data() };
          setProduct(productData);
          setMainImage(productData.mainImage);
        } else {
          setError('No such document!');
        }
      } catch (err) {
        setError(`Error fetching product: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleThumbnailClick = (imageUrl) => {
    setMainImage(imageUrl);
  };

  const handleAddToCart = () => {
    const availableQuantity = product.availability?.[0]?.quantity || 0;
    if (selectedQuantity > availableQuantity) {
      return;
    }

    const cartItem = cart.find(item => item.id === product.id);
    if (cartItem) {
      updateCartItem({ ...cartItem, quantity: cartItem.quantity + selectedQuantity });
    } else {
      addToCart({ ...product, quantity: selectedQuantity });
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <nav className="breadcrumb">
        <Link to="/shop">Shop</Link> / <Link to={`/shop/${section}`}>{section ? section.replace('-', ' ') : ''}</Link> / <span>{product.name}</span>
      </nav>
      <div>
        <div className="product-details-page">
          <div>
            <div className="product-details">
              <div className="product-images">
                {mainImage && <img src={mainImage} alt={product.name} className="main-image" />}
                <div className="thumbnail-images">
                  <img
                    src={product.mainImage}
                    alt={`${product.name} main thumbnail`}
                    className={`thumbnail ${mainImage === product.mainImage ? 'active' : ''}`}
                    onClick={() => handleThumbnailClick(product.mainImage)}
                  />
                  {product.thumbnailImages && product.thumbnailImages.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`${product.name} thumbnail`}
                      className={`thumbnail ${mainImage === image ? 'active' : ''}`}
                      onClick={() => handleThumbnailClick(image)}
                    />
                  ))}
                </div>
              </div>
              <div className="product-info">
                <h1>{product.name}</h1>
                <h3>Product description</h3>
                <p className="description">{product.description}</p>
                <h3>Price</h3>
                <p className="price">€{product.price.toFixed(2)}</p>
                <h3>Availability</h3>
                <p className="availability">
                  {product.availability && product.availability[0]?.quantity > 0
                    ? `${product.availability[0].quantity} in stock`
                    : 'Out of stock'}
                </p>
                <div className="shop-selection">
                  <h3 className="shop-selection label">Quantity:</h3>
                  <select
                    value={selectedQuantity}
                    onChange={(e) => setSelectedQuantity(parseInt(e.target.value, 10))}
                    disabled={!product.availability || product.availability[0]?.quantity <= 0}
                  >
                    {product.availability &&
                      [...Array(product.availability[0]?.quantity || 0).keys()].map(n => (
                        <option key={n + 1} value={n + 1}>{n + 1}</option>
                      ))}
                  </select>
                </div>
                <button
                  className="add-to-cart-button"
                  onClick={handleAddToCart}
                  disabled={!product.availability || product.availability[0]?.quantity <= 0}
                >
                  {product.availability && product.availability[0]?.quantity > 0
                    ? 'Add to Cart'
                    : 'Out of Stock'}
                </button>
              </div>
            </div>
            <div className="product-images-cart-main">
              <div>
              <h3>Product images</h3>
              </div>
              <div className="product-images-cart">
              {product.thumbnailImages && product.thumbnailImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name} thumbnail`}
                  className={`thumbnail ${mainImage === image ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(image)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ProductDetailsPage;
