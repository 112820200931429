import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import { AiFillInstagram } from "react-icons/ai";
import { RiCrossLine } from "react-icons/ri";
import logoFull from '../assets/burnify-nobg.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-logo">
          <Link to="/" className="logo-text-footer"><img src={logoFull} alt="" /></Link>
        </div>
        <div className="footer-contact">
          <h2>Interested to work with us? Drop us a line</h2>
          <Link to="/contact">Contact</Link>    
        </div>
        <nav className="footer-icons">
          <ul className="icon-links">
            <li>
            <div className="header-icon-cross">
                <RiCrossLine />
              </div>
            </li>
            <li>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <AiFillInstagram size={25} />
              </a>
            </li>
            <li>
              <Link to="/contact">
                <FaEnvelope size={25} />
              </Link>
            </li>
            <li>
              <div className="header-icon-cross">
                <RiCrossLine />
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer-bottom">
        <div className="footer-office">
          <h4>PRAGUE OFFICE</h4>
          <p>Our Prague office leads our European operations with a focus on innovative design.</p>
        </div>
        <div className="footer-office">
          <h4>CAREERS</h4>
          <Link to="/contact">Contact</Link>   
        </div>
        <div className="footer-office">
          <h4>PROTECTION</h4>
          <div>
          This site is protected by the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </div>
        </div>
        <div className="footer-office">
          <h4>ADMIN</h4>
          <Link to="/login">Admin page</Link>
        </div>

      </div>
      <div className="footer-copyright">
        <p>&copy; {new Date().getFullYear()} Burnify. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
