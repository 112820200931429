import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { FaEnvelope } from 'react-icons/fa';
import { AiFillInstagram } from "react-icons/ai";
import { RiCrossLine } from "react-icons/ri";
import { CgMenuRound } from "react-icons/cg";
import logo from '../assets/burnify-logo-nobg.png'


const Header = () => {
  const { cartCount } = useCart();
  const [isResponsive, setIsResponsive] = useState(false);

  const toggleNav = () => {
    setIsResponsive(!isResponsive);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <Link to="/" className="logo-text"><img src={logo} alt="logo" /></Link>
        </div>
        <nav className={`topnav ${isResponsive ? "responsive" : ""}`} id="myTopnav">
          <a href="javascript:void(0);" className="icon" onClick={toggleNav}>
            <CgMenuRound size={40} className="i"/>
          </a>
          <Link to="/" className="active">Home</Link>
          <Link to="/shop">Shop</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/cart">Cart ({cartCount})</Link>
          
        </nav>
        <nav>
          <ul className="icon-links">
            <li>
              <div className="header-icon-cross">
                <RiCrossLine />
              </div>
            </li>
            <li>
              <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                <AiFillInstagram size={25} />
              </a>
            </li>
            <li>
              <Link to="/contact">
                <FaEnvelope size={25} />
              </Link>
            </li>
            <li>
              <div className="header-icon-cross">
                <RiCrossLine />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
