import React from 'react';
import { useCart } from '../contexts/CartContext';
import { Link } from 'react-router-dom';
import '../styles.css';

const CartPage = () => {
  const { cart, removeFromCart } = useCart();

  return (
    <div className='cart-page'>
      <h1>Shopping Cart</h1>
      <div className="cart-summary">
        <h3>Summary</h3>
        <p>Total: €{cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}</p>
        <Link to="/checkout" className="checkout-button">Proceed to Checkout</Link>
      </div>
      <div className="cart-list">
        {cart.map((product) => (
          <div key={product.id} className="cart-item">
            <img src={product.mainImage} alt={product.name} />
            <div className="cart-details">
              <h3>{product.name}</h3>
              <p>Size: {product.availability[0].size}</p>
              <p>€{product.price.toFixed(2)}</p>
              <div className="quantity-control">
                <label>Quantity:</label>
                <p>{product.quantity}</p>
              </div>
              <button onClick={() => removeFromCart(product)}>Remove</button>
            </div>
          </div>
        ))}
      </div>
 
    </div>
  );
};

export default CartPage;
