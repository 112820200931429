import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import ContactPage from './components/ContactPage';
import ShopPage from './components/ShopPage';
import CartPage from './components/CartPage';
import Checkout from './components/Cart/Checkout';
import ProductDetailsPage from './components/ProductDetailsPage';
import Login from './components/Auth/Login';
import AddProduct from './components/Admin/AddProduct';
import { AuthProvider } from './contexts/AuthContext';
import AdminPage from './components/Admin/AdminPage';
import Header from './components/Header';
import Footer from './components/Footer';
import CookiesPopup from './components/CookiesPopup';
import { CartProvider } from './contexts/CartContext';
import EditProduct from './components/Admin/EditProduct';
import PrivateRoute from './components/PrivateRoute'; 

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <Header />
        <main>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/shop/:section" element={<ShopPage />} />
            <Route path="/shop/:section/product/:productId" element={<ProductDetailsPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin/add" element={<AddProduct />} />
            <Route path="/admin/edit/:productId" element={<PrivateRoute><EditProduct /></PrivateRoute>} />
          </Routes>
        </main>
        <Footer />
        <CookiesPopup />
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
