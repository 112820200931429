import React, { useEffect, useState } from 'react';
import { useCart } from '../../contexts/CartContext';
import { firestore } from '../../firebase';
import { collection, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useForm } from "react-hook-form";
import { Helmet } from 'react-helmet';

const Checkout = () => {
  const { cart, clearCart } = useCart();
  const [result, setResult] = useState("");
  const { register, handleSubmit, setValue } = useForm();
  const [captchaToken, setCaptchaToken] = useState('');

  useEffect(() => {
    if (captchaToken) {
      setValue('recaptcha_response', captchaToken);
    }
  }, [captchaToken, setValue]);

  const handleRecaptcha = (event) => {
    event.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LeJIxoqAAAAANBTh27JTWpiooT8W3YRoYtb55RH', { action: 'submit' }).then((token) => {
        setCaptchaToken(token);
        // submit form only after reCAPTCHA token is set
        document.getElementById('checkout-form').submit();
      });
    });
  };

  const onSubmit = async (data) => {
    setResult("Sending....");
    const formData = new FormData();

    formData.append('access_key', '0ea88d8a-ac1b-415c-8bda-852faf6fa261');
    formData.append('recaptcha_response', captchaToken);

    for (const key in data) {
      formData.append(key, data[key]);
    }

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    }).then((res) => res.json());

    if (response.success) {
      setResult("Order placed successfully!");
      document.getElementById("checkout-form").reset();

      const { firstName, lastName, email, address, city, state, zip } = data;
      const orderItems = cart.map(item => {
        const { imageUrl, availability, description, ...rest } = item;
        return rest;
      });

      const ordersCollection = collection(firestore, 'orders');
      await addDoc(ordersCollection, {
        user: { firstName, lastName, email, address, city, state, zip, name: `${firstName} ${lastName}` },
        items: orderItems,
        createdAt: new Date(),
      });

      for (const item of cart) {
        const productRef = doc(firestore, 'products', item.id);
        const productDoc = await getDoc(productRef);
        const currentAvailability = productDoc.data().availability;
        const updatedQuantity = currentAvailability.quantity - item.quantity;

        await updateDoc(productRef, {
          'availability.quantity': updatedQuantity
        });
      }

      clearCart();
    } else {
      setResult(response.message);
    }
  };

  const handleEmailChange = (event) => {
    setValue("replyto", event.target.value);
  };

  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div className="checkout">
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LeJIxoqAAAAANBTh27JTWpiooT8W3YRoYtb55RH" async defer></script>
      </Helmet>
      <h1>Checkout</h1>
      <span>{result}</span>
      <div className='checkout-container'>
        <form id="checkout-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="contact-info">
            <h3>Contact information</h3>
            <input type="hidden" name="replyto" {...register("replyto")} />
            <input type="hidden" name="from_name" value="Burned_noreply"/>
            <input type="hidden" name="subject" {...register("subject")} />
            <input type="hidden" name="custom_message" {...register("custom_message")} />
            <input type="checkbox" name="botcheck" className="hidden" style={{ display: 'none' }} />
            <input type="text" name="firstName" placeholder="First name" {...register("firstName", { required: true })} />
            <input type="text" name="lastName" placeholder="Last name" {...register("lastName", { required: true })} />
            <input type="email" name="email" placeholder="Email" {...register("email", { required: true })} onChange={handleEmailChange} />
          </div>
          <div className="shipping-address">
            <h3>Shipping address</h3>
            <input type="text" name="address" placeholder="Address" {...register("address", { required: true })} />
            <input type="text" name="apartment" placeholder="Apartment, suite, etc. (optional)" {...register("apartment")} />
            <input type="text" name="city" placeholder="City" {...register("city", { required: true })} />
            <input type="text" name="state" placeholder="State" {...register("state", { required: true })} />
            <input type="text" name="zip" placeholder="ZIP code" {...register("zip", { required: true })} />
            <input type="text" name="phone" placeholder="Phone (optional)" {...register("phone")} />
            <input type="hidden" name="recaptcha_response" {...register("recaptcha_response")} id="recaptchaResponse" />
          </div>
          <div className="checkout-disclaimer">
            This site is protected by the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </div>
          <button type="submit" onClick={handleRecaptcha} className="g-recaptcha checkout-button" data-sitekey="6LeJIxoqAAAAANBTh27JTWpiooT8W3YRoYtb55RH" data-callback="onSubmit" data-action="submit">
            Place Order
          </button>
        </form>
        <div className="order-summary">
          <h3>Order Summary</h3>
          <ul>
            {cart.map((item, index) => (
              <li key={index}>
                {item.name} - €{item.price.toFixed(2)} (Size: {item.availability.size}, Quantity: {item.quantity})
              </li>
            ))}
          </ul>
          <p>Total Price: €{totalPrice.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
