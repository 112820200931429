import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCTaJGYatlNDyMqOm1yzjzM4LtHJUxvTI8",
  authDomain: "burned-54c53.firebaseapp.com",
  databaseURL: "https://burned-54c53-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "burned-54c53",
  storageBucket: "burned-54c53.appspot.com",
  messagingSenderId: "9872670519",
  appId: "1:9872670519:web:2728930626a8a5233b24b2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);


export { auth, firestore, storage };
