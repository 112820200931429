// src/components/Admin/AddProduct.js

import React, { useRef, useState } from 'react';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { storage, firestore } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function AddProduct() {
  const nameRef = useRef();
  const priceRef = useRef();
  const descriptionRef = useRef();
  const sizeRef = useRef();
  const quantityRef = useRef();
  const sectionRef = useRef();
  const imageRef = useRef();
  const [message, setMessage] = useState('');

  const sections = ['T-Shirts', 'Prints', 'Limited-Edition'];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseInt(quantityRef.current.value, 10) <= 0) {
      setMessage("Quantity must be greater than 0.");
      return;
    }
    const product = {
      name: nameRef.current.value,
      price: parseFloat(priceRef.current.value),
      description: descriptionRef.current.value,
      section: sectionRef.current.value,
      availability: [{ size: sizeRef.current.value, quantity: parseInt(quantityRef.current.value, 10) }]
    };
    const files = Array.from(imageRef.current.files);
    if (files.length === 0) {
      setMessage("Please select at least one image.");
      return;
    }
    await addProductWithImages(product, files);
  };

  const uploadImage = async (file, productId) => {
    const imageStorageRef = ref(storage, `products/${productId}/${file.name}`);
    await uploadBytes(imageStorageRef, file);
    return await getDownloadURL(imageStorageRef);
  };

  const addProductWithImages = async (product, files) => {
    try {
      const productDocRef = await addDoc(collection(firestore, 'products'), product);
      const productId = productDocRef.id;

      const imageUrls = await Promise.all(files.map(file => uploadImage(file, productId)));

      await updateDoc(doc(firestore, 'products', productId), {
        mainImage: imageUrls[0],
        thumbnailImages: imageUrls.slice(1),
        imageUrls: imageUrls
      });

      setMessage("Product added successfully!");
      nameRef.current.value = '';
      priceRef.current.value = '';
      descriptionRef.current.value = '';
      sizeRef.current.value = '';
      quantityRef.current.value = '';
      sectionRef.current.value = '';
      imageRef.current.value = '';
    } catch (error) {
      console.error('Error adding product: ', error);
      setMessage("Error adding product.");
    }
  };

  return (
    <div className="add-product-page">
      <h1>Add Product</h1>
      <div className="add-product-container">
        <form onSubmit={handleSubmit}>
          <input type="text" ref={nameRef} required placeholder="Product Name" />
          <input type="number" ref={priceRef} required placeholder="Product Price" />
          <input type="text" ref={descriptionRef} required placeholder="Product Description" />
          <input type="text" ref={sizeRef} required placeholder="Product Size" />
          <input type="number" ref={quantityRef} required placeholder="Product Quantity" />
          <select ref={sectionRef} required>
            <option value="">Select Section</option>
            {sections.map((section, index) => (
              <option key={index} value={section}>{section}</option>
            ))}
          </select>
          <input type="file" ref={imageRef} multiple required />
          <button type="submit">Add Product</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}
