// src/components/Admin/EditProduct.js

import React, { useRef, useEffect, useState } from 'react';
import { firestore, storage } from '../../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function EditProduct() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const nameRef = useRef();
  const priceRef = useRef();
  const descriptionRef = useRef();
  const sizeRef = useRef();
  const quantityRef = useRef();
  const sectionRef = useRef();
  const imageRef = useRef();
  const [product, setProduct] = useState(null);
  const [message, setMessage] = useState('');
  const sections = ['T-Shirts', 'Prints', 'Limited-Edition'];

  useEffect(() => {
    const fetchProduct = async () => {
      const productDoc = await getDoc(doc(firestore, 'products', productId));
      if (productDoc.exists()) {
        setProduct(productDoc.data());
      }
    };
    fetchProduct();
  }, [productId]);

  const uploadImage = async (file, productId) => {
    const imageStorageRef = ref(storage, `products/${productId}/${file.name}`);
    await uploadBytes(imageStorageRef, file);
    return await getDownloadURL(imageStorageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedProduct = {
      name: nameRef.current.value,
      price: parseFloat(priceRef.current.value),
      description: descriptionRef.current.value,
      section: sectionRef.current.value,
      availability: [{ size: sizeRef.current.value, quantity: parseInt(quantityRef.current.value, 10) }]
    };

    const files = Array.from(imageRef.current.files);
    let imageUrls = product.imageUrls || [];

    if (files.length > 0) {
      const uploadedImageUrls = await Promise.all(files.map(file => uploadImage(file, productId)));
      imageUrls = uploadedImageUrls;
    }

    try {
      await updateDoc(doc(firestore, 'products', productId), {
        ...updatedProduct,
        mainImage: imageUrls[0] || product.mainImage,
        thumbnailImages: imageUrls.slice(1) || product.thumbnailImages,
        imageUrls: imageUrls.length > 0 ? imageUrls : product.imageUrls,
      });

      setMessage("Product updated successfully!");
      navigate('/admin');
    } catch (error) {
      console.error('Error updating product: ', error);
      setMessage("Error updating product.");
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-product-page">
      <h2>Edit Product</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" ref={nameRef} defaultValue={product.name} required placeholder="Product Name" />
        <input type="number" ref={priceRef} defaultValue={product.price} required placeholder="Product Price" />
        <input type="text" ref={descriptionRef} defaultValue={product.description} required placeholder="Product Description" />
        <input type="text" ref={sizeRef} defaultValue={product.availability[0]?.size} required placeholder="Product Size" />
        <input type="number" ref={quantityRef} defaultValue={product.availability[0]?.quantity} required placeholder="Product Quantity" />
        <select ref={sectionRef} defaultValue={product.section} required>
          <option value="">Select Section</option>
          {sections.map((section, index) => (
            <option key={index} value={section}>{section}</option>
          ))}
        </select>
        <input type="file" ref={imageRef} multiple />
        <button type="submit">Update Product</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}
