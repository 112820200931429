import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import '../styles.css';

const ShopPage = () => {
  const { section } = useParams();
  const [products, setProducts] = useState([]);
  const { addToCart, updateCartItem, cart } = useCart();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      let productsQuery;
      if (section) {
        const decodedSection = decodeURIComponent(section);
        productsQuery = query(collection(firestore, 'products'), where('section', '==', decodedSection));
      } else {
        productsQuery = collection(firestore, 'products');
      }
      try {
        const productsSnapshot = await getDocs(productsQuery);
        const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsList);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, [section]);

  const handleQuantityChange = (productId, quantity) => {
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [productId]: { ...prevOptions[productId], quantity }
    }));
    setErrorMessages(prevErrors => ({
      ...prevErrors,
      [productId]: ''
    }));
  };

  const handleAddToCart = (product) => {
    const selectedOption = selectedOptions[product.id] || { quantity: 1 };
    const availableQuantity = product.availability?.[0]?.quantity || 0;
    if (selectedOption.quantity > availableQuantity) {
      setErrorMessages(prevErrors => ({
        ...prevErrors,
        [product.id]: `Only ${availableQuantity} items available`
      }));
      return;
    }
    const cartItem = cart.find(item => item.id === product.id);
    if (cartItem) {
      updateCartItem({ ...cartItem, quantity: cartItem.quantity + selectedOption.quantity });
    } else {
      addToCart({ ...product, quantity: selectedOption.quantity });
    }
  };

  return (
    <div className='shop-page'>
      <nav className="submenu">
        <ul>
          <li><Link to="/shop">All Products</Link></li>
          <li><Link to="/shop/T-Shirts">T-Shirts</Link></li>
          <li><Link to="/shop/Prints">Prints</Link></li>
          <li><Link to="/shop/Limited-Edition">Limited Edition</Link></li>
        </ul>
      </nav>
      <div className="section-product">
        <div className="breadcrumb">
          <Link to="/">Home</Link> / <span>{section ? section : 'All Products'}</span>
        </div>

        <h1>{section ? section : 'All Products'}</h1>
        <div className="product-list">
          {products.map((product) => {
            const isOutOfStock = !product.availability || (Array.isArray(product.availability) && product.availability.every(avail => avail.quantity === 0));
            return (
              <div 
                key={product.id} 
                className="product-card"
                onClick={() => navigate(`/shop/${section}/product/${product.id}`)}
                style={{ cursor: 'pointer' }}
              >
                <img src={product.mainImage} alt={product.name} />
                <h3>{product.name}</h3>
                <p>€{product.price.toFixed(2)}</p>
                {isOutOfStock ? (
                  <p className="out-of-stock">Out of Stock</p>
                ) : (
                  <div>
                    <p className="quantity">{product.availability[0]?.quantity > 0 ? `${product.availability[0].quantity} in stock` : 'Out of Stock'}</p>
                    {product.availability && product.availability[0] && (
                      <>
                        <div className="shop-selection">
                          <div className="shop-selection label">Size:</div>
                          <div className="shop-selection value">{product.availability[0].size}</div>
                        </div>
                        <div className="shop-selection">
                          <div className="shop-selection label">Quantity:</div>
                          <select
                            value={selectedOptions[product.id]?.quantity || 1}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleQuantityChange(product.id, parseInt(e.target.value, 10));
                            }}
                            onClick={(e) => e.stopPropagation()} // Prevent navigation on select click
                          >
                            {[...Array(product.availability[0].quantity).keys()].map(n => (
                              <option key={n + 1} value={n + 1}>{n + 1}</option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                    {errorMessages[product.id] && <p className="error-message">{errorMessages[product.id]}</p>}
                    <button 
                      className="product-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToCart(product);
                      }}
                    >
                      Add to Cart
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShopPage;
