import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import '../styles.css';
import { PiMouseScroll } from "react-icons/pi";
import { RiCrossLine } from "react-icons/ri";
import mainImg from '../assets/tshirtNoBackround.png';
import sellImg from '../assets/wedoimg.png';

// video
import heroVideo from '../assets/video/buildingInFire.mp4';

const LandingPage = () => {
  const [products, setProducts] = useState([]);
  const [limitedEditionProducts, setLimitedEditionProducts] = useState([]);
  const aboutVideoRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(firestore, 'products');
      const productsSnapshot = await getDocs(productsCollection);
      const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const limitedEdition = productsList.filter(product => product.section === 'Limited-Edition');
      const regularProducts = productsList.filter(product => product.section !== 'Limited-Edition');

      setProducts(regularProducts);
      setLimitedEditionProducts(limitedEdition);
    };
    fetchProducts();
  }, []);

  const handleVideoMouseEnter = () => {
    if (aboutVideoRef.current) {
      aboutVideoRef.current.play();
    }
  };

  const handleVideoMouseLeave = () => {
    if (aboutVideoRef.current) {
      aboutVideoRef.current.pause();
      aboutVideoRef.current.currentTime = 0;
    }
  };

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleProductClick = (product) => {
    const section = product.section ? product.section.replace(' ', '-') : 'All-Products';
    navigate(`/shop/${section}/product/${product.id}`);
  };

  return (
    <div id='hero' className="landing-page">
      <section className="hero">
        <div className="hero-text">
          <div className="icon-cross">
            <RiCrossLine className=''/>
          </div>
          <h2 onClick={() => navigate('/shop/Limited-Edition')} style={{ cursor: 'pointer' }}>Limited Editions</h2>
          <hr />
          <p>One T-Shirt Every Month</p>
          <div className="icon-cross">
            <RiCrossLine />
          </div>
        </div>
        <div className="hero-image-container">
          <img src={mainImg} alt="Hero" />
        </div>
        <div className="hero-text">
          <h1>Discover the Highest Quality Apparel</h1>
          <Link to="/shop" className="cta-button">Shop</Link>
        </div>
      </section>
      
      <div className="icon-center">
        <PiMouseScroll className={`scroll-icon ${scrollPosition > 50 ? 'scroll-up' : 'scroll-down'}`} />
        <p>Scroll Down</p>
      </div>

      <section className="limited-edition">
        <h2>Limited Edition</h2>
        <div className="product-list">
          {limitedEditionProducts.map((product) => {
            const isOutOfStock = !product.quantity || (Array.isArray(product.quantity) && product.quantity.every(avail => avail.quantity === 0));
            return (
              <div 
                key={product.id} 
                className="hero-product-card"
                onClick={() => handleProductClick(product)}
                style={{ cursor: 'pointer' }}
              >
                <img src={product.mainImage} alt={product.name} />
                <h3>{product.name}</h3>
                <p>€{product.price.toFixed(2)}</p>
                {isOutOfStock ? (
                  <p className="out-of-stock">Out of Stock</p>
                ) : (
                  <p className="quantity">{product.quantity?.[0]?.quantity} in stock</p>
                )}
              </div>
            );
          })}
        </div>
      </section>

      <section className="new-section">
        <div className='new-section-container'>
        <div>
        <img onClick={() => navigate('/shop/Prints')} style={{ cursor: 'pointer' }} src={sellImg} alt="New Section" />
        <p className='imgDescription'>Hilling’s Burning of the Old South Church, Bath, Maine, 1854</p>
        </div>
        <div className='new-sec-container'>

        <h2 onClick={() => navigate('/shop/Prints')} style={{ cursor: 'pointer' }}>We Also Sell Prints</h2>

        <p>Explore our collection of high-quality prints. Just like our apparel, our prints are designed with meticulous attention to detail and a passion for art. Perfect for adding a unique touch to your space, these prints capture the essence of our brand and the transformative journey between life and the inferno. Shop now and bring a piece of Burnify's unique artistry into your home.</p>
        </div>
        </div>
      </section>

      <section className="products">
        <h2>Available Products</h2>
        <div className="product-list">
          {products.map((product) => {
            const isOutOfStock = !product.availability || (Array.isArray(product.availability) && product.availability.every(avail => avail.quantity === 0));
            return (
              <div 
                key={product.id} 
                className="hero-product-card"
                onClick={() => handleProductClick(product)}
                style={{ cursor: 'pointer' }}
              >
                <img src={product.mainImage} alt={product.name} />
                <h3>{product.name}</h3>
                <p>€{product.price.toFixed(2)}</p>
                {isOutOfStock ? (
                  <p className="out-of-stock">Out of Stock</p>
                ) : (
                  <p className="quantity">{product.availability?.[0]?.quantity} in stock</p>
                )}
              </div>
            );
          })}
        </div>
      </section>

      <section className="about">
        <div className='about-container'>
          <div 
            className="about-video-container" 
            onMouseEnter={handleVideoMouseEnter} 
            onMouseLeave={handleVideoMouseLeave}
          >
            <video className="about-video" src={heroVideo} ref={aboutVideoRef} muted />
          </div>
          <div className="about-text">
            <h2>About <span className='logo-text'> Burnify</span> </h2>
            <p>Discover the highest quality apparel from the fire of inferno at Burnify. Our collections, made with the love of Satan, are designed for the transformative journey between life and the afterlife. Embrace the fusion of art and premium design with every piece, and make a statement with Burnify.</p>
          </div>
        </div>
      </section>

      <section className="reviews">
        <h2>Customer Reviews</h2>
        <div className="review">
          <p>"I couldn't be happier! The t-shirts are amazing. I will definitely be ordering more." - John Doe</p>
        </div>
        <div className="review">
          <p>"Amazing quality and fast shipping. Highly recommended!" - Jane Smith</p>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
